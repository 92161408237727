<template>
    <div ref="rootElRef"
        class="toast align-items-center text-white border-0" 
        :class="{
            ['bg-' + toast.type]: true
        }"
        role="alert" 
        aria-live="assertive" 
        aria-atomic="true"
        v-on="{
            'hidden.bs.toast': handleHidden
        }">
        <div class="d-flex">
            <div v-if="toast.message === errorMessages.EMAIL_NOT_REGISTERED" class="toast-body">
                {{ toast.message }} <a :href="`${currentUrl}/post-login/#/registration`" class="text-warning">here</a>
            </div>
            <div v-else class="toast-body">
                {{ toast.message }}
            </div>
            <button type="button"
                class="btn btn-overlay-light me-2 m-auto p-2 rounded-circle"
                aria-label="Close"
                @click="dismiss">
                <Icon symbol="x" class="icon"></Icon>
            </button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { Toast as ToastType } from "@/modules/core/types";
import Toast from "bootstrap/js/dist/toast";
import { Icon } from '@/modules/core/components';
import { onBeforeUnmount, onMounted, PropType, ref } from "vue";
import errorMessages from "../../error-messages";

export interface ToastTypeMethods {
    dismiss: () => void;
    show: () => void;
}

defineProps({
    toast: {
        type: Object as PropType<ToastType>,
        required: true
    }
})

const emit = defineEmits(['hidden']);

const rootElRef = ref<HTMLElement>();
let toastInstance: Toast;

const dismiss = () => {
    toastInstance?.hide();
}

const show = () => {
    toastInstance?.show();
}

const handleHidden = () => {
    emit("hidden");
}

const currentUrl = ref('')

currentUrl.value = window.location.origin;

onMounted(() => {
    toastInstance = new Toast(rootElRef.value!, { delay: 2000 });
    toastInstance.show();
})

onBeforeUnmount(() => {
    toastInstance.dispose();
})

</script>